import './Customers.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faChevronRight, faEdit, faTrash, faUsers } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import img1 from './assets/compiled/jpg/4.jpg';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Customers() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const gotoDataCustomers = () => {
    navigate('/DataCustomers');
  };

  const Orders = [
    { id: 1, CREATED: '2024-08-11', NAME: ' Bikdash', IMG: img1, EMAIL: 'abdo@gmail.com', PHONE: '01090299674', STATUS: 'ss' },
    { id: 2, CREATED: '2024-08-11', NAME: ' Bikdash', IMG: img1, EMAIL: 'abdo@gmail.com', PHONE: '01090299674', STATUS: '' },
    { id: 3, CREATED: '2024-08-11', NAME: ' Bikdash', IMG: img1, EMAIL: 'abdo@gmail.com', PHONE: '01090299674', STATUS: '' }
  ];

  const listOrders = t('customers.columns', { returnObjects: true });
  const [showProdcts, setShowProdcts] = useState(null);
  const toggleShowProducts = (id) => setShowProdcts(showProdcts === id ? null : id);

  const categories = t('customers.categories', { returnObjects: true });
  const [openCategory, setOpenCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState({});

  const handleCategoryClick = (id) => setOpenCategory(openCategory === id ? null : id);
  const handleSelectCategory = (id, category) => {
    setSelectedCategory({ ...selectedCategory, [id]: category });
    setOpenCategory(null);
  };

  return (
    <div className="Customers">
      <div className='container-fluid'>
        <div className='row align-items-center'>
          <div className='name_header d-flex'>
            <i className='font-size3'><FontAwesomeIcon icon={faUsers} /></i>
            <p>{t('customers.title')}</p>
          </div>
        </div>

        <div className='container'>
          <div className='row'>
            <div className='Pending'>
              {categories.map((e, i) => <p key={i}>{e}</p>)}
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='row'>
            <div className='sarsh'>
              <input placeholder={t('customers.search_placeholder')} />
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='Orders'>
            <div className='Orders_border row justify-content-around'>
              {listOrders.map((e, index) => (
                <p className='col-2 text-center' key={index}>{e}</p>
              ))}
            </div>

            {Orders.map((order) => (
              <div key={order.id}>
                <div className='Orders_border row justify-content-around'>
                  <div className='aa col-2' onClick={gotoDataCustomers}>
                    <img src={order.IMG} alt="customer" />
                    <div>
                      <p className='p'>{order.NAME}</p>
                      <p className='p'>{order.EMAIL}</p>
                    </div>
                  </div>
                  <p className='col-2 text-center' onClick={gotoDataCustomers}>{order.PHONE}</p>
                  <p className='col-2 text-center' onClick={gotoDataCustomers}>{order.CREATED}</p>
                  <p className='col-2 text-center'>
                    <div className="box_add" onClick={() => handleCategoryClick(order.id)}>
                      <div className="heade">
                        <span>{selectedCategory[order.id] || categories[1]}</span>
                        <i><FontAwesomeIcon icon={faChevronRight} /></i>
                      </div>
                      {openCategory === order.id &&
                        <div className="cat2">
                          {categories.map((e, i) =>
                            <p key={i} onClick={() => handleSelectCategory(order.id, e)}>{e}</p>
                          )}
                        </div>
                      }
                    </div>
                  </p>
                  <p className='col-2 text-center'>
                    <span className='span_icon'><FontAwesomeIcon icon={faTrash} /></span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Customers;