import React, { useState, useCallback } from "react";
import './AddAdveritsement.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAd, faArrowLeft, faCartPlus, faChevronRight, faCloudUpload, faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from 'react-dropzone';
import { useNavigate } from "react-router-dom";

function AddAdveritsement() {

    const navigate = useNavigate();
    const goBack = () => {
      navigate(-1)
    }



    // حالة للصندوق الأول (صورة واحدة فقط)
    const [singleImage, setSingleImage] = useState([]);
    const [errorSingleImage, setErrorSingleImage] = useState();

    // حالة للصندوق الثاني (حتى 5 صور)
    const [multipleImages, setMultipleImages] = useState([]);
    const [errorMultipleImages, setErrorMultipleImages] = useState();

    // دالة لتحميل الصورة الواحدة
    const onDropSingle = useCallback((acceptedFiles) => {
        const imageFiles = acceptedFiles.filter(file => file.type.startsWith('image/'));

        if (imageFiles.length > 0) {
            if (singleImage.length >= 1) {
                setErrorSingleImage('You can upload only one image');
            } else {
                setSingleImage(imageFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })));
                setErrorSingleImage('');
            }
        } else {
            setErrorSingleImage('Please upload only images');
        }
    }, [singleImage]);

    const { getRootProps: getRootPropsSingle, getInputProps: getInputPropsSingle } = useDropzone({ onDrop: onDropSingle, multiple: false });


    
    const [categories, setCategories] = useState([
        "WholeSale",
        "Categorie",
      ]);
      const[errorImage, setErrorImage] = useState();
      const[errorFile, setErrorFile] = useState();


    const text = categories[0]
    const[icon, setIcon] = useState(false)
    function handelClick() {
        setIcon(!icon)
    }

  const [images, setImages] = useState([]);
  const[taskName, setTaskName] = useState()
  const[tasURL, setTaskURL] = useState()
  const[taskType, setTaskType] = useState()
  const[Descraption, setDescraption] = useState()
    const Data = {
        image: images,
        taskName: taskName,
        taskType: taskType,
        tasURL: tasURL,
        Descraption: Descraption,
    }
    console.log(Data)

   const ResetInputs = () => {
        setTaskName(""); // مسح قيمة الاسم
        setDescraption(""); // مسح قيمة الوصف
        setTaskURL(""); // مسح قيمة رابط المشروع
        setTaskType(""); // مسح قيمة نوع المهمة
        setImages([]); // مسح قائمة الصور
    };
    const [errorImageUpload, setErrorImageUpload] = useState('');
    const[errorname, seterrorName] = useState()
    const[errordescription, seterrorDescription] = useState()
    const[errorcategory, seterrorCategory] = useState()
    const[errorimage, setErrorimage] = useState()
    const[errorurl, seterrorURL] = useState()
    function UploadTask(e) {
              e.preventDefault();
              let isError = false;
          
              if (!taskName) {
                  seterrorName("Error name");
                  isError = true;
              } else {
                  seterrorName("");
              }
          
              if (!Descraption) {
                  seterrorDescription("Error Description");
                  isError = true;
              } else {
                  seterrorDescription("");
              }
              if (!images.length) {
                  setErrorimage("Error color");
                  isError = true;
              } else {
                  setErrorimage("");
              }
      
              if (!singleImage.length && !multipleImages.length) {
                setErrorImageUpload("Please upload at least one image");
                isError = true;
            } else {
                setErrorImageUpload("");
            }
    
              if (!tasURL) {
                  seterrorURL("Error Date & Time");
                  isError = true;
              } else {
                  seterrorURL("");
              }
              if (!taskType) {
                  seterrorCategory("Error Category");
                  isError = true;
              } else {
                  seterrorCategory("");
              }
          
              if (isError) {
                  return;
              }
          
              ResetInputs()
          }
    return (
        <div className="AddAdveritsement">
            <div className="container">
                <div className='row align-items-center'>
                  <div className='name_header d-flex'>
                    <i className="font-size3"><FontAwesomeIcon icon={faAd} /></i>
                    <p>AddAdveritsement</p>
                  </div>
                  <div className="container icon">
                  <i className="i" onClick={goBack}><FontAwesomeIcon icon={faArrowLeft} /></i>
                  </div>
                </div>
                <form className="container row justify-content-around">

                    <div className="row justify-content-between">
                        {errorname && <p className="error" >{errorname}</p>}
                        <div className="divll col-7">
                            <label for='name'>ADDADVERITSEMENT NAME</label>
                            <input type="text" id="Name" onChange={(e) => setTaskName(e.target.value)} value={taskName} placeholder="Type AddAdveritsement Name"/>
                        </div>

                        <div className="box col-4" onClick={handelClick}>
                            <label for='tags'>ADDADVERITSEMENT TYPE</label>
                            <div className="heade">
                                <span>{taskType ? taskType : text}</span>
                                <i className={`${icon ? 'activ_p' : 'noActiv_p'}`}><FontAwesomeIcon icon={faChevronRight}/></i>
                            </div>
                            {icon &&
                                <div className="cat">
                                    {categories.map((e, i) => 
                                        <p onClick={() => (setTaskType(e), setIcon(!icon))}>{e}</p>
                                    )}
                                </div>
                            }
                        </div>

                        <div className="col-7">
                        {/* الصندوق الأول - صورة واحدة فقط */}
                        <div className="Add_img">
                            <p className="title">ADDADVERITSEMENT IMAGE</p>
                            {errorImageUpload && <p className="error">{errorImageUpload}</p>}
                            <div className="add" {...getRootPropsSingle()}>
                                <input {...getInputPropsSingle()} />
                                {!singleImage.length && (
                                    <div className="upload-placeholder">
                                        <i><FontAwesomeIcon icon={faCloudUpload} /></i>
                                        <p>Click Here upload Photo</p>
                                    </div>
                                )}
                                <div className="image-preview single">
                                    {singleImage.map((file, index) => (
                                        <img key={index} src={file.preview} alt={`upload preview ${index}` } className="img"/>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>  
                    <button className="button2 col-5 m-5" onClick={UploadTask} type="sbmit">Create</button>
                </form>
            </div>
        </div>
    );
}

export default AddAdveritsement;