import React, { useState, useCallback } from "react";
import './AddCategoire.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCartPlus, faChevronRight, faCloudUpload } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from 'react-dropzone';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function AddCategoire() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    
    // Navigate back function
    const goBack = () => {
        navigate(-1);
    }

    // State management
    const [singleImage, setSingleImage] = useState([]);
    const [multipleImages, setMultipleImages] = useState([]);
    const [taskName, setTaskName] = useState('');
    const [taskURL, setTaskURL] = useState('');
    const [taskType, setTaskType] = useState('');
    const [description, setDescription] = useState('');
    const [errorImageUpload, setErrorImageUpload] = useState('');
    const [errorName, setErrorName] = useState('');
    const [errorDescription, setErrorDescription] = useState('');
    const [errorURL, setErrorURL] = useState('');
    const [errorCategory, setErrorCategory] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [icon, setIcon] = useState(false);

    const categories = [
        "Draft",
        "Active",
        "Archive",
    ];

    const onDropSingle = useCallback((acceptedFiles) => {
        const imageFiles = acceptedFiles.filter(file => file.type.startsWith('image/'));
        if (imageFiles.length > 0) {
            if (singleImage.length >= 1) {
                setErrorImageUpload(t('addCategory.errorSingleImage'));
            } else {
                setSingleImage(imageFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })));
                setErrorImageUpload('');
            }
        } else {
            setErrorImageUpload(t('addCategory.errorImage'));
        }
    }, [singleImage, t]);

    const onDropMultiple = useCallback((acceptedFiles) => {
        const imageFiles = acceptedFiles.filter(file => file.type.startsWith('image/'));
        if (imageFiles.length > 0) {
            if (multipleImages.length + imageFiles.length <= 5) {
                setMultipleImages(prevImages => [
                    ...prevImages,
                    ...imageFiles.map(file => Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    }))
                ]);
                setErrorImageUpload('');
            } else {
                setErrorImageUpload(t('addCategory.errorMultipleImages'));
            }
        } else {
            setErrorImageUpload(t('addCategory.errorImage'));
        }
    }, [multipleImages, t]);

    const { getRootProps: getRootPropsSingle, getInputProps: getInputPropsSingle } = useDropzone({ onDrop: onDropSingle, multiple: false });
    const { getRootProps: getRootPropsMultiple, getInputProps: getInputPropsMultiple } = useDropzone({ onDrop: onDropMultiple });

    const resetInputs = () => {
        setTaskName('');
        setDescription('');
        setTaskURL('');
        setTaskType('');
        setSingleImage([]);
        setMultipleImages([]);
    };

    const uploadTask = (e) => {
        e.preventDefault();
        let isError = false;

        // Reset all errors
        setErrorName('');
        setErrorDescription('');
        setErrorImageUpload('');
        setErrorURL('');
        setErrorCategory('');

        if (!taskName) {
            setErrorName(t('addCategory.errorName'));
            isError = true;
        }

        if (!description) {
            setErrorDescription(t('addCategory.errorDescription'));
            isError = true;
        }

        if (!singleImage.length && !multipleImages.length) {
            setErrorImageUpload(t('addCategory.errorImageUpload'));
            isError = true;
        }

        if (!taskURL) {
            setErrorURL(t('addCategory.errorURL'));
            isError = true;
        }

        if (!taskType) {
            setErrorCategory(t('addCategory.errorCategory'));
            isError = true;
        }

        if (isError) {
            setShowPopup(true);
            setTimeout(() => setShowPopup(false), 3000);
            return;
        }

        resetInputs();
    };

    const handleClick = () => {
        setIcon(!icon);
    };

    return (
        <div className="AddCategoire">
            <div className="container">
                <div className='row align-items-center'>
                    <div className='name_header d-flex'>
                        <i><FontAwesomeIcon icon={faCartPlus} /></i>
                        <p>{t('addCategory.title')}</p>
                    </div>
                    <div className="container icon">
                        <i className="i" onClick={goBack}><FontAwesomeIcon icon={faArrowLeft} /></i>
                    </div>
                </div>
                <form className="container row justify-content-around">
                    <div className="col-6">
                        <div className="Add_img">
                            <p>{t('addCategory.singlePhoto')}</p>
                            <div className="add" {...getRootPropsSingle()}>
                                <input {...getInputPropsSingle()} />
                                {!singleImage.length && (
                                    <div className="upload-placeholder">
                                        <i><FontAwesomeIcon icon={faCloudUpload} /></i>
                                        <p>{t('addCategory.dragAndDropSingle')}</p>
                                    </div>
                                )}
                                <div className="image-preview single">
                                    {singleImage.map((file, index) => (
                                        <img key={index} src={file.preview} alt={`upload preview ${index}`} className="img" />
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="Add_img">
                            <p>{t('addCategory.multiplePhotos')}</p>
                            <div className="add" {...getRootPropsMultiple()}>
                                <input {...getInputPropsMultiple()} />
                                {!multipleImages.length && (
                                    <div className="upload-placeholder">
                                        <i><FontAwesomeIcon icon={faCloudUpload} /></i>
                                        <p>{t('addCategory.dragAndDropMultiple')}</p>
                                    </div>
                                )}
                                <div className={`image-preview ${multipleImages.length === 1 ? 'single' : 'multiple'} row`}>
                                    {multipleImages.map((file, index) => (
                                        <img key={index} src={file.preview} alt={`upload preview ${index}`} className="imgs col-4" />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="project_data col-5">
                        <div className="div1">
                            <label htmlFor='taskName'>{t('addCategory.taskName')}</label>
                            <input type="text" id="taskName" onChange={(e) => setTaskName(e.target.value)} value={taskName} />
                        </div>
                        <div className="box" onClick={handleClick}>
                            <label htmlFor='taskType'>{t('addCategory.taskType')}</label>
                            <div className="header">
                                <span>{taskType ? taskType : t('addCategory.chooseType')}</span>
                                <i className={`${icon ? 'activ_p' : 'noActiv_p'}`}><FontAwesomeIcon icon={faChevronRight} /></i>
                            </div>
                            {icon &&
                                <div className="cat">
                                    {categories.map((e, i) =>
                                        <p key={i} onClick={() => (setTaskType(e), setIcon(!icon))}>{e}</p>
                                    )}
                                </div>
                            }
                        </div>
                        <div className="div2">
                            <label htmlFor='description'>{t('addCategory.description')}</label>
                            <input type="text" id="description" onChange={(e) => setDescription(e.target.value)} value={description} />
                        </div>
                        <div className="div2">
                            <label htmlFor='taskURL'>{t('addCategory.taskURL')}</label>
                            <input type="text" id="taskURL" onChange={(e) => setTaskURL(e.target.value)} value={taskURL} />
                        </div>

                        <button onClick={uploadTask} type="submit">{t('addCategory.create')}</button>
                    </div>
                </form>
            </div>
            <div className={`popup ${showPopup ? 'show' : ''}`}>{t('addCategory.errorFields')}</div>
        </div>
    );
}

export default AddCategoire;
