import './Adveritsement.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAd, faCartPlus, faEdit, faPlus, faPlusCircle, faShoppingCart, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import img1 from './images/88209500.jpg';
import img2 from './images/Asset 19.png';
import img3 from './images/Asset 22.png';
import { useTranslation } from 'react-i18next';

function Adveritsement() {
  const { t } = useTranslation(); // استخدام الترجمة
  const navigate = useNavigate();

  const gotoCategoiresDetial = () => {
    navigate('/CategoiresDetial');
  };

  const gotoAddAdveritsement = () => {
    navigate('/AddAdveritsement');
  };

  const Orders = [
    { IMAGE: img1, NAME: 'Shirt', STATUS: 5, ACTION: '89 JOD ' },
    { IMAGE: img2, NAME: 'Glasses', STATUS: 5, ACTION: '89 JOD ' },
    { IMAGE: img3, NAME: 'Bag', STATUS: 5, ACTION: '89 JOD ' },
  ];

  const listOrders = ['NAME', 'TYPE', 'CREATED', 'ACTION'];

  const [showProdcts, setShowProdcts] = useState(null);

  const toggleShowProducts = (id) => {
    setShowProdcts(showProdcts === id ? null : id);
  };

  return (
    <div className="Adveritsement">
      <div className='container-fluid'>
        <div className='row align-items-center'>
          <div className='name_header d-flex'>
            <i className='font-size3'><FontAwesomeIcon icon={faAd} /></i>
            <p>{t('advertisement.title')}</p> {/* ترجمة العنوان */}
          </div>
        </div>

        <div className='container'>
          <div className='row'>
            <div className='sarsh'>
              <input placeholder={t('advertisement.search_placeholder')} /> {/* ترجمة placeholder */}
              <div className='AddCat' onClick={gotoAddAdveritsement}>
                <span><FontAwesomeIcon icon={faPlus} /></span>
                <span>{t('advertisement.add_ad')}</span> {/* ترجمة النص */}
              </div>
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='Orders'>
            <div className='Orders_border row justify-content-around'>
              {listOrders.map((e, index) => (
                <p className='col-3 text-center' key={index}>{t(`advertisement.columns.${index}`)}</p> 
              ))}
            </div>

            {Orders.map((order) => (
              <div key={order.id}>
                <div className='Orders_border row justify-content-around'>
                  <p className='col-3 text-center'><img src={order.IMAGE} alt={order.NAME} /></p>
                  <p className='col-3 text-center'>{order.NAME}</p>
                  <p className='col-3 text-center'>{order.STATUS}</p>
                  <p className='col-3 text-center'>
                    <span className='span_icon'><FontAwesomeIcon icon={faEdit} /></span>
                    <span className='span_icon'><FontAwesomeIcon icon={faTrash} /></span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Adveritsement;
