import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        "sidebar": {
            "statistics": "Statistics",
            "orders_management": "Orders Management",
            "customers": "Customers",
            "categories": "Categories",
            "transactions": "Transactions",
            "brand": "Brand",
            "advertisement": "Advertisement",
            "add_product": "Add Product",
            "products_list": "Products List",
            "languages": "Languages",
            "arabic": "Arabic",
            "english": "English",
            "logout": "Logout",
            "home": "Home",
            "chat": "Chat",
            "favorites": "Favorites",
            "about": "About",
            "contact_us": "Contact Us"
        },
        "ordersManagement": {
          "recent_orders": "Recent Orders",
          "search_placeholder": "Search by order id",
          "order_id": "ORDER ID",
          "created": "CREATED",
          "customer": "CUSTOMER",
          "total": "TOTAL",
          "profit": "PROFIT",
          "status": "STATUS",
          "categories": ["Pending", "Confirmed", "Processing", "Picked", "Shipped", "Delivered", "Cancelled"]
        },
        "customers": {
          "title": "Customers",
          "search_placeholder": "Search by User Email",
          "columns": ["NAME", "PHONE NUMBER", "CREATED", "STATUS", "ACTION"],
          "categories": ["Pending Approvals", "Approved", "Closed", "Banned"]
        },
        "categoires": {
          "title": "Categories",
          "search_placeholder": "Search by Name",
          "add_categoire": "Add Category",
          "recent_orders": "Recent Orders",
          "columns": ["CATEGORY ID", "CATEGORY IMAGE", "CATEGORY NAME", "STATUS", "ACTION"],
          "categories": ["Draft", "Active", "Archive"]
        },
        "transactions": {
          "title": "Transactions",
          "search_placeholder": "Search",
          "recent_orders": "Recent Orders",
          "columns": ["ID", "CUSTOMER", "DATE", "PAYMENT METHOD", "TOTAL", "METHOD", "STATUS"],
          "categories": ["Credit/Debit Card", "Cash On Delivery", "Installments"]
        },
        "brand": {
          "title": "Brand",
          "search_placeholder": "Search by Name",
          "add_category": "Add Category",
          "recent_orders": "Recent Orders",
          "columns": ["CATEGORY ID", "CATEGORY IMAGE", "CATEGORY NAME", "STATUS", "ACTION"],
          "categories": ["Draft", "Active", "Archive"]
        },
        "advertisement": {
          "title": "Advertisement",
          "search_placeholder": "Search for advertisement",
          "add_ad": "Add Advertisement",
          "columns": ["Name", "Type", "Created", "Action"]
        },
        "products_list": {
          "product_name": "Product Name",
          "category": "Category",
          "company": "Company",
          "inventory": "Inventory",
          "status": "Status",
          "action": "Action",
          "search": "Search",
          "active": "Active",
          "draft": "Draft",
          "archive": "Archive",
          "products_list": "Products List"
            
        },
        "addProduct": {
          "title": "Add Product",
          "productDescription": "Product Description",
          "media": "Media",
          "dragAndDropSingle": "Drag and drop a file to upload",
          "dragAndDropMultiple": "Drag and drop files to upload",
          "productStatus": "Product Status",
          "discountPrice": "Discount Price",
          "price": "Price",
          "quantity": "Quantity",
          "sku": "SKU",
          "variant": "Variant",
          "category": "Category",
          "tags": "Tags",
          "company": "Company",
          "create": "Create",
          "errorFields": "Please fill all fields!",
          "errorImageUpload": "Please upload only images"
        },
        "addCategory": {
          "title": "Add Category",
          "singlePhoto": "Single Photo",
          "dragAndDropSingle": "Drag and drop the image here or click to upload",
          "multiplePhotos": "Multiple Photos",
          "dragAndDropMultiple": "Drag and drop the images here or click to upload",
          "taskName": "Task Name",
          "taskType": "Task Type",
          "chooseType": "Choose Type",
          "description": "Description",
          "taskURL": "Task URL",
          "create": "Create",
          "errorSingleImage": "You can only upload one image.",
          "errorMultipleImages": "You can upload up to 5 images.",
          "errorImage": "Please upload an image only.",
          "errorImageUpload": "Please upload at least one image.",
          "errorName": "Please enter the task name.",
          "errorDescription": "Please enter the description.",
          "errorURL": "Please enter the task URL.",
          "errorCategory": "Please select the task type.",
          "errorFields": "Please fill out all fields correctly."
        },
        "statistics": {
          "title": "Statistics",
          "chooseDate": "Choose date to view data",
          "chooseComparisonDate": "Choose comparison date",
          "netProfits": "Net Profits",
          "last7Days": "Last 7 Days",
          "averageOrderValue": "Average order value",
          "grossProfits": "Gross Profits",
          "profitMargins": "Profit margins",
          "durationOfStay": "Duration of stay on site",
          "bounceRate": "Bounce rate",
          "visitorsBought": "Visitors who bought",
          "visitorsDidntBuy": "Visitors who didn't buy",
          "inventoryManagement": "Inventory management"
        },
        
      }
    },
    ar: {
      translation: {
        "sidebar": {
        "statistics": "الإحصائيات",
        "orders_management": "إدارة الطلبات",
        "customers": "العملاء",
        "categories": "الفئات",
        "transactions": "المعاملات",
        "brand": "العلامة التجارية",
        "advertisement": "الإعلانات",
        "add_product": "إضافة منتج",
        "products_list": "قائمة المنتجات",
        "languages": "اللغات",
        "arabic": "العربية",
        "english": "الإنجليزية",
        "logout": "تسجيل الخروج",
        "home": "الرئيسية",
        "chat": "الدردشة",
        "favorites": "المفضلة",
        "about": "حول",
        "contact_us": "اتصل بنا"
        },
        "ordersManagement": {
          "recent_orders": "الطلبات الأخيرة",
          "search_placeholder": "ابحث بواسطة معرف الطلب",
          "order_id": "معرف الطلب",
          "created": "تاريخ الإنشاء",
          "customer": "العميل",
          "total": "الإجمالي",
          "profit": "الربح",
          "status": "الحالة",
          "categories": ["قيد الانتظار", "تم التأكيد", "قيد المعالجة", "تم التجميع", "تم الشحن", "تم التوصيل", "تم الإلغاء"]
        },
        "customers": {
          "title": "العملاء",
          "search_placeholder": "ابحث عن طريق البريد الإلكتروني",
          "columns": ["الاسم", "رقم الهاتف", "تاريخ الإنشاء", "الحالة", "الإجراءات"],
          "categories": ["في انتظار الموافقة", "تمت الموافقة", "مغلق", "محظور"]
        },
        "categoires": {
          "title": "الفئات",
          "search_placeholder": "ابحث بالاسم",
          "add_categoire": "إضافة فئة",
          "recent_orders": "الطلبات الأخيرة",
          "columns": ["معرف الفئة", "صورة الفئة", "اسم الفئة", "الحالة", "الإجراءات"],
          "categories": ["مسودة", "نشط", "أرشيف"]
        },
        "transactions": {
          "title": "المعاملات",
          "search_placeholder": "بحث",
          "recent_orders": "الطلبات الأخيرة",
          "columns": ["الرقم", "العميل", "التاريخ", "طريقة الدفع", "الإجمالي", "الطريقة", "الحالة"],
          "categories": ["بطاقة ائتمان/خصم", "الدفع عند الاستلام", "أقساط"]
        },
        "brand": {
          "title": "العلامة التجارية",
          "search_placeholder": "ابحث بالاسم",
          "add_category": "إضافة فئة",
          "recent_orders": "الطلبات الأخيرة",
          "columns": ["معرف الفئة", "صورة الفئة", "اسم الفئة", "الحالة", "الإجراءات"],
          "categories": ["مسودة", "نشط", "أرشيف"]
        },
        "advertisement": {
          "title": "الإعلانات",
          "search_placeholder": "ابحث عن إعلان",
          "add_ad": "إضافة إعلان",
          "columns": ["الاسم", "النوع", "تاريخ الإنشاء", "الإجراء"]
        },
        "products_list": {
          "product_name": "اسم المنتج",
          "category": "الفئة",
          "company": "الشركة",
          "inventory": "المخزون",
          "status": "الحالة",
          "action": "الإجراء",
          "search": "بحث",
          "active": "نشط",
          "draft": "مسودة",
          "archive": "أرشيف",
          "products_list": "قائمة المنتجات"
        },
        "addProduct": {
          "title": "إضافة منتج",
          "productDescription": "وصف المنتج",
          "media": "الوسائط",
          "dragAndDropSingle": "اسحب وأسقط ملفًا لتحميله",
          "dragAndDropMultiple": "اسحب وأسقط الملفات لتحميلها",
          "discountPrice": "سعر الخصم",
          "price": "السعر",
          "quantity": "الكمية",
          "sku": "SKU",
          "variant": "النسخة",
          "productStatus": "حالة المنتج",
          "category": "الفئة",
          "tags": "العلامات",
          "company": "الشركة",
          "create": "إنشاء",
          "errorFields": "يجب ملء جميع الحقول!"
        },
        "addCategory": {
          "title": "إضافة فئة",
          "singlePhoto": "صورة فردية",
          "dragAndDropSingle": "قم بسحب وإفلات الصورة هنا أو انقر للتحميل",
          "multiplePhotos": "صور متعددة",
          "dragAndDropMultiple": "قم بسحب وإفلات الصور هنا أو انقر للتحميل",
          "taskName": "اسم المهمة",
          "taskType": "نوع المهمة",
          "chooseType": "اختر النوع",
          "description": "الوصف",
          "taskURL": "رابط المهمة",
          "create": "إنشاء",
          "errorSingleImage": "يمكنك تحميل صورة واحدة فقط.",
          "errorMultipleImages": "يمكنك تحميل ما يصل إلى 5 صور.",
          "errorImage": "يرجى تحميل صورة فقط.",
          "errorImageUpload": "يرجى تحميل صورة واحدة على الأقل.",
          "errorName": "يرجى إدخال اسم المهمة.",
          "errorDescription": "يرجى إدخال الوصف.",
          "errorURL": "يرجى إدخال رابط المهمة.",
          "errorCategory": "يرجى اختيار نوع المهمة.",
          "errorFields": "يرجى تعبئة جميع الحقول بشكل صحيح."
        },
        "statistics": {
          "title": "الإحصائيات",
          "chooseDate": "اختر التاريخ لعرض البيانات",
          "chooseComparisonDate": "اختر تاريخ المقارنة",
          "netProfits": "صافي الأرباح",
          "last7Days": "آخر 7 أيام",
          "averageOrderValue": "متوسط قيمة الطلب",
          "grossProfits": "إجمالي الأرباح",
          "profitMargins": "هامش الربح",
          "durationOfStay": "مدة البقاء على الموقع",
          "bounceRate": "معدل الارتداد",
          "visitorsBought": "الزوار الذين اشتروا",
          "visitorsDidntBuy": "الزوار الذين لم يشتروا",
          "inventoryManagement": "إدارة المخزون"
        }
      }
    }
  },
  lng: "en", // اللغة الافتراضية
  fallbackLng: "en",

  interpolation: {
    escapeValue: false // مش محتاجه مع React
  }

});

export default i18n;