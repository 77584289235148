import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const RevenueComparisonChart = ({ data }) => {
  const [selectedDate, setSelectedDate] = useState('2023-01-01');
  const [comparisonDate, setComparisonDate] = useState('2022-01-01');

  const handleDateChange = (event) => setSelectedDate(event.target.value);
  const handleComparisonDateChange = (event) => setComparisonDate(event.target.value);

  const selectedYear = selectedDate.split('-')[0];
  const selectedMonth = selectedDate.split('-')[1];
  const selectedDay = selectedDate.split('-')[2];

  const comparisonYear = comparisonDate.split('-')[0];
  const comparisonMonth = comparisonDate.split('-')[1];
  const comparisonDay = comparisonDate.split('-')[2];

  const dummyData = {
    labels: Array.from({ length: 31 }, (_, i) => `${i + 1}`),
    '2023': {
      '01': {
        '01': [120, 150, 180, 200, 230, 260, 280, 290, 300, 310, 320, 330, 340, 350, 360, 370, 380, 390, 400, 410, 420, 430, 440, 450, 460, 470, 480, 490, 500, 510, 520],
        '02': [120, 150, 180, 200, 230, 260, 280, 290, 300, 310, 320, 330, 340, 350, 360, 370, 380, 390, 400, 410, 420, 430, 440, 450, 460, 470, 480, 490, 500, 510, 520],
        '03': [120, 150, 180, 200, 230, 260, 280, 290, 300, 310, 320, 330, 340, 350, 360, 370, 380, 390, 400, 410, 420, 430, 440, 450, 460, 470, 480, 490, 500, 510, 520],
      },
      '02': {
        '01': [120, 150, 180, 200, 230, 260, 280, 290, 300, 310, 320, 330, 340, 350, 360, 370, 380, 390, 400, 410, 420, 430, 440, 450, 460, 470, 480, 490, 500, 510, 520],
        '02': [120, 150, 180, 200, 230, 260, 280, 290, 300, 310, 320, 330, 340, 350, 360, 370, 380, 390, 400, 410, 420, 430, 440, 450, 460, 470, 480, 490, 500, 510, 520],
        '03': [120, 150, 180, 200, 230, 260, 280, 290, 300, 310, 320, 330, 340, 350, 360, 370, 380, 390, 400, 410, 420, 430, 440, 450, 460, 470, 480, 490, 500, 510, 520],
      },
    },
    '2022': {
      '01': {
        '01': [100, 130, 160, 190, 220, 250, 280, 290, 300, 310, 320, 330, 340, 350, 360, 370, 380, 390, 400, 410, 420, 430, 440, 450, 460, 470, 480, 490, 500, 510, 520],
        '02': [100, 130, 160, 190, 220, 250, 280, 290, 300, 310, 320, 330, 340, 350, 360, 370, 380, 390, 400, 410, 420, 430, 440, 450, 460, 470, 480, 490, 500, 510, 520],
        '03': [100, 130, 160, 190, 220, 250, 280, 290, 300, 310, 320, 330, 340, 350, 360, 370, 380, 390, 400, 410, 420, 430, 440, 450, 460, 470, 480, 490, 500, 510, 520],
      },
    },
  };

  const chartData = data || dummyData;

  const yearData = chartData[selectedYear];
  const monthData = yearData ? yearData[selectedMonth] : null;
  const dayData = monthData ? monthData[selectedDay] || [] : [];

  const comparisonYearData = chartData[comparisonYear];
  const comparisonMonthData = comparisonYearData ? comparisonYearData[comparisonMonth] : null;
  const comparisonDayData = comparisonMonthData ? comparisonMonthData[comparisonDay] || [] : [];

  const chartConfig = {
    labels: chartData.labels,
    datasets: [
      {
        label: `إيرادات ${selectedYear} (${selectedMonth}/${selectedDay})`,
        data: dayData,
        fill: false,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
      },
      {
        label: `إيرادات ${comparisonYear} (${comparisonMonth}/${comparisonDay})`,
        data: comparisonDayData,
        fill: false,
        backgroundColor: 'rgba(153,102,255,0.4)',
        borderColor: 'rgba(153,102,255,1)',
      },
    ],
  };

  return (
    <div className='row'>
      <div className='col-6'>
        <label>اختر التاريخ لعرض البيانات</label>
        <input
        className=' w-100 m-0 mb-3'
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
        />
      </div>

      <div className='col-6'>
        <label>اختر تاريخ المقارنة</label>
        <input
        className='w-100 m-0 mb-3'
          type="date"
          value={comparisonDate}
          onChange={handleComparisonDateChange}
        />
      </div>

      <div className='col-12'>
        <div style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
          {dayData.length === 0 && comparisonDayData.length === 0 ? (
            <p style={{ textAlign: 'center', paddingTop: '20px' }}>لا توجد بيانات لعرضها!</p>
          ) : (
            <Line data={chartConfig} />
          )}
        </div>
      </div>
    </div>
  );
};

export default RevenueComparisonChart;