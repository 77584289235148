import React, { useState, useCallback } from "react";
import './AddProduct.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus, faChevronRight, faCloudUpload, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from 'react-dropzone';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function AddProduct() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [singleImage, setSingleImage] = useState([]);
    const [multipleImages, setMultipleImages] = useState([]);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [productType, setProductType] = useState('');
    const [discountPrice, setDiscountPrice] = useState('');
    const [price, setPrice] = useState('');
    const [category, setCategory] = useState('');
    const [tags, setTags] = useState('');
    const [company, setCompany] = useState('');
    const [quantity, setQuantity] = useState('');
    const [sku, setSku] = useState('');
    const [variant, setVariant] = useState('');

    const [errorImageUpload, setErrorImageUpload] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [icon, setIcon] = useState(false);
    const [categories, setCategories] = useState([
        "مسودة",
        "نشط",
        "أرشيف",
    ]);

    const onDropSingle = useCallback((acceptedFiles) => {
        const imageFiles = acceptedFiles.filter(file => file.type.startsWith('image/'));

        if (imageFiles.length > 0) {
            setSingleImage(imageFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            setErrorImageUpload('');
        } else {
            setErrorImageUpload(t('addProduct.errorImageUpload'));
        }
    }, [t]);

    const onDropMultiple = useCallback((acceptedFiles) => {
        const imageFiles = acceptedFiles.filter(file => file.type.startsWith('image/'));

        if (imageFiles.length > 0 && multipleImages.length + imageFiles.length <= 5) {
            setMultipleImages(prevImages => [
                ...prevImages,
                ...imageFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }))
            ]);
            setErrorImageUpload('');
        } else {
            setErrorImageUpload(t('addProduct.errorImageUpload'));
        }
    }, [multipleImages, t]);

    const { getRootProps: getRootPropsSingle, getInputProps: getInputPropsSingle } = useDropzone({ onDrop: onDropSingle, multiple: false });
    const { getRootProps: getRootPropsMultiple, getInputProps: getInputPropsMultiple } = useDropzone({ onDrop: onDropMultiple });

    const resetInputs = () => {
        setTitle("");
        setDescription("");
        setProductType("");
        setSingleImage([]);
        setMultipleImages([]);
        setDiscountPrice("");
        setPrice("");
        setCategory("");
        setTags("");
        setCompany("");
        setQuantity("");
        setSku("");
        setVariant("");
    };
    
    function uploadTask(e) {
        e.preventDefault();
        let isError = false;
    
        // تحقق من كل الحقول
        if (
            !title || 
            !description || 
            !productType || 
            !discountPrice || 
            !price || 
            !category || 
            !tags || 
            !company || 
            !quantity || 
            !sku || 
            !variant || 
            (!singleImage.length && !multipleImages.length)
        ) {
            isError = true;
            setShowPopup(true);
            setTimeout(() => setShowPopup(false), 3000);
        }
    
        if (!isError) {
            setShowPopup(false);
            resetInputs();
        }
    }

    const text = t('addProduct.productStatus');

    function handleClick() {
        setIcon(!icon);
    }

    return (
        <div className="AddProduct">
            <div className="container">
                <div className='row align-items-center'>
                    <div className='name_header d-flex'>
                        <i className="font-size3"><FontAwesomeIcon icon={faPlusCircle} /></i>
                        <p>{t('addProduct.title')}</p>
                    </div>
                </div>
                <div className="container">
                    <form className="row justify-content-between mt-3">
                        <div className="col-8">
                            <div className="div1">
                                <label htmlFor='title'>{t('addProduct.title')}</label>
                                <input type="text" id="title" onChange={(e) => setTitle(e.target.value)} value={title} />
                            </div>

                            <div className="div2">
                                <label htmlFor='description'>{t('addProduct.productDescription')}</label>
                                <input type="text" id="description" onChange={(e) => setDescription(e.target.value)} value={description} />
                            </div>

                            <div className="Add_img">
                                <p>{t('addProduct.media')}</p>
                                {errorImageUpload && <p className="error">{errorImageUpload}</p>}
                                <div className="add" {...getRootPropsSingle()}>
                                    <input {...getInputPropsSingle()} />
                                    {!singleImage.length && (
                                        <div className="upload-placeholder">
                                            <i><FontAwesomeIcon icon={faCloudUpload} /></i>
                                            <p>{t('addProduct.dragAndDropSingle')}</p>
                                        </div>
                                    )}
                                    <div className="image-preview single">
                                        {singleImage.map((file, index) => (
                                            <img key={index} src={file.preview} alt={`معاينة التحميل ${index}`} className="img" />
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="Add_img">
                                <p>{t('addProduct.media')}</p>
                                {errorImageUpload && <p className="error">{errorImageUpload}</p>}
                                <div className="add" {...getRootPropsMultiple()}>
                                    <input {...getInputPropsMultiple()} />
                                    {!multipleImages.length && (
                                        <div className="upload-placeholder">
                                            <i><FontAwesomeIcon icon={faCloudUpload} /></i>
                                            <p>{t('addProduct.dragAndDropMultiple')}</p>
                                        </div>
                                    )}
                                    <div className={`image-preview ${multipleImages.length === 1 ? 'single' : 'multiple'} row`}>
                                        {multipleImages.map((file, index) => (
                                            <img key={index} src={file.preview} alt={`معاينة التحميل ${index}`} className="imgs col-4 mb-3" />
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-between mt-4">
                                <div className="div1 col-2">
                                    <label htmlFor='discountPrice'>{t('addProduct.discountPrice')}</label>
                                    <input type="text" id="discountPrice" onChange={(e) => setDiscountPrice(e.target.value)} value={discountPrice} />
                                </div>

                                <div className="div2 col-2">
                                    <label htmlFor='price'>{t('addProduct.price')}</label>
                                    <input type="text" id="price" onChange={(e) => setPrice(e.target.value)} value={price} />
                                </div>

                                <div className="div2 col-2">
                                    <label htmlFor='quantity'>{t('addProduct.quantity')}</label>
                                    <input type="text" id="quantity" onChange={(e) => setQuantity(e.target.value)} value={quantity} />
                                </div>

                                <div className="div2 col-2">
                                    <label htmlFor='sku'>{t('addProduct.sku')}</label>
                                    <input type="text" id="sku" onChange={(e) => setSku(e.target.value)} value={sku} />
                                </div>

                                <div className="div2 col-2">
                                    <label htmlFor='variant'>{t('addProduct.variant')}</label>
                                    <input type="text" id="variant" onChange={(e) => setVariant(e.target.value)} value={variant} />
                                </div>
                            </div>
                        </div>

                        <div className="project_data col-4">
                            <div className="box" onClick={handleClick}>
                                <label htmlFor='productType'>{t('addProduct.productStatus')}</label>
                                <div className="heade">
                                    <span>{productType ? productType : text}</span>
                                    <i><FontAwesomeIcon icon={faChevronRight} /></i>
                                </div>
                                {icon &&
                                    <div className="cat">
                                        {categories.map((e, i) =>
                                            <p key={i} onClick={() => (setProductType(e), setIcon(!icon))}>{e}</p>
                                        )}
                                    </div>
                                }
                            </div>

                            <div className="div1">
                                <label htmlFor='category'>{t('addProduct.category')}</label>
                                <input type="text" id="category" onChange={(e) => setCategory(e.target.value)} value={category} />
                            </div>

                            <div className="div2">
                                <label htmlFor='tags'>{t('addProduct.tags')}</label>
                                <input type="text" id="tags" onChange={(e) => setTags(e.target.value)} value={tags} />
                            </div>

                            <div className="div2">
                                <label htmlFor='company'>{t('addProduct.company')}</label>
                                <input type="text" id="company" onChange={(e) => setCompany(e.target.value)} value={company} />
                            </div>
                        </div>
                        <button className='col-6' onClick={uploadTask} type="submit">{t('addProduct.create')}</button>
                    </form>
                </div>
            </div>
            <div className={`popup ${showPopup ? 'show' : ''}`}>{t('addProduct.errorFields')}</div>
        </div>
    );
}

export default AddProduct;