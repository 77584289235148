import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './OrdersManagement.css';

function OrdersManagement() {
  const { t } = useTranslation();

  const Orders = [
    { id: 1, CREATED: '2024-08-11', CUSTOMER: 'mohammed Bikdash', TOTAL: 5, PROFIT: '89 JOD ', STATUS: '' },
    { id: 2, CREATED: '2024-08-11', CUSTOMER: 'mohammed Bikdash', TOTAL: 5, PROFIT: '89 JOD ', STATUS: '' },
    { id: 3, CREATED: '2024-08-11', CUSTOMER: 'mohammed Bikdash', TOTAL: 5, PROFIT: '89 JOD ', STATUS: '' }
  ];

  const listOrders = [
    t('ordersManagement.order_id'),
    t('ordersManagement.created'),
    t('ordersManagement.customer'),
    t('ordersManagement.total'),
    t('ordersManagement.profit'),
    t('ordersManagement.status')
  ];

  const Orders2 = [
    { id: 1, SKU: 'SKU', NAME: 'shirt', PRICE: 5, VARIANT: 'laptop', QTY: 2, DISC: '1%', TOTAL: '5' },
    { id: 2, SKU: 'SKU', NAME: 'shirt', PRICE: 5, VARIANT: 'laptop', QTY: 2, DISC: '1%', TOTAL: '5' },
    { id: 3, SKU: 'SKU', NAME: 'shirt', PRICE: 5, VARIANT: 'laptop', QTY: 2, DISC: '1%', TOTAL: '5' },
    { id: 4, SKU: 'SKU', NAME: 'shirt', PRICE: 5, VARIANT: 'laptop', QTY: 2, DISC: '1%', TOTAL: '5' },
    { id: 5, SKU: 'SKU', NAME: 'shirt', PRICE: 5, VARIANT: 'laptop', QTY: 2, DISC: '1%', TOTAL: '5' }
  ];

  const listOrders2 = ['id', 'SKU', 'NAME', 'PRICE', 'VARIANT', 'QTY', 'DISC', 'TOTAL'];

  const [showProdcts, setShowProdcts] = useState(null);
  const toggleShowProducts = (id) => setShowProdcts(showProdcts === id ? null : id);

  const categories = t('ordersManagement.categories', { returnObjects: true });
  const [openCategory, setOpenCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState({});

  const handleCategoryClick = (id) => setOpenCategory(openCategory === id ? null : id);
  const handleSelectCategory = (id, category) => {
    setSelectedCategory({ ...selectedCategory, [id]: category });
    setOpenCategory(null);
  };

  return (
    <div className="OrdersManagement">
      <div className='container-fluid'>
        <div className='row align-items-center'>
          <div className='name_header d-flex'>
            <i className='font-size3'><FontAwesomeIcon icon={faCartPlus} /></i>
            <p>{t('sidebar.orders_management')}</p>
          </div>
        </div>

        <div className='container'>
          <div className='row'>
            <div className='Pending'>
              {categories.map((e, i) => <p key={i}>{e}</p>)}
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='row'>
            <div className='sarsh'>
              <input placeholder={t('ordersManagement.search_placeholder')} />
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='Orders'>
            <div className='Recent'>{t('ordersManagement.recent_orders')}</div>
            <div className='Orders_border row'>
              {listOrders.map((e, index) => (
                <p className='col-2 text-center' key={index}>{e}</p>
              ))}
            </div>

            {Orders.map((order) => (
              <div key={order.id}>
                <div className='Orders_border row'>
                  <p className='col-2 text-center' onClick={() => toggleShowProducts(order.id)}>{order.id}</p>
                  <p className='col-2 text-center' onClick={() => toggleShowProducts(order.id)}>{order.CREATED}</p>
                  <p className='col-2 text-center' onClick={() => toggleShowProducts(order.id)}>{order.CUSTOMER}</p>
                  <p className='col-2 text-center' onClick={() => toggleShowProducts(order.id)}>{order.TOTAL}</p>
                  <p className='col-2 text-center' onClick={() => toggleShowProducts(order.id)}>{order.PROFIT}</p>
                  <p className='col-2 text-center'>
                    <div className="box_add" onClick={() => handleCategoryClick(order.id)}>
                      <div className="heade">
                        <span>{selectedCategory[order.id] || categories[1]}</span>
                        <i><FontAwesomeIcon icon={faChevronRight} /></i>
                      </div>
                      {openCategory === order.id &&
                        <div className="cat2">
                          {categories.map((e, i) =>
                            <p key={i} onClick={() => handleSelectCategory(order.id, e)}>{e}</p>
                          )}
                        </div>
                      }
                    </div>
                  </p>
                </div>

                <div className={`ProductsWrapper ${showProdcts === order.id ? 'active' : ''}`}>
                  <div className='Orders_border3 row justify-content-around'>
                    {listOrders2.map((item, index) => (
                      <p className='col-1 text-center' key={index}>{item}</p>
                    ))}
                  </div>
                  {Orders2.map((product, index) => (
                    <div className='Orders_border2 row justify-content-around' key={index}>
                      <p className='col-1 text-center'>{product.id}</p>
                      <p className='col-1 text-center'>{product.SKU}</p>
                      <p className='col-1 text-center'>{product.NAME}</p>
                      <p className='col-1 text-center'>{product.PRICE}</p>
                      <p className='col-1 text-center'>{product.VARIANT}</p>
                      <p className='col-1 text-center'>{product.QTY}</p>
                      <p className='col-1 text-center'>{product.DISC}</p>
                      <p className='col-1 text-center'>{product.TOTAL}</p>
                    </div>
                  ))}
                </div>

              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrdersManagement;