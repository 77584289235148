import './App.css';
import {BrowserRouter, Routes, Route } from "react-router-dom";
import { useState } from 'react';
import Sidebar from './Main/Sidebar';
import Statistics from './Main/Statistics';
import OrdersManagement from './Main/OrdersManagement';
import 'bootstrap/dist/css/bootstrap.min.css';
import Customers from './Main/Customers';
import DataCustomers from './Main/DataCustomers';
import Categoires from './Main/Categoires';
import AddCategoire from './Main/AddCategoire';
import CategoiresDetial from './Main/CategoiresDetial';
import Transactions from './Main/Transactions';
import Brand from './Main/Brand';
import AddCompany from './Main/AddCompany';
import Adveritsement from './Main/Adveritsement';
import AddAdveritsement from './Main/AddAdveritsement';
import ProductsList from './Main/ProductsList';
import AddProduct from './Main/AddProduct';
import Favorites from './Main/Favorites';



import { useEffect } from 'react';



function App() {

  const [getLocalStorage, setGetLocalStorage] = useState(window.localStorage.getItem('log'));
  const valueLocalStorage = getLocalStorage ? JSON.parse(getLocalStorage) : null;
  const [show, setShow] = useState(valueLocalStorage ? valueLocalStorage.role : null);

  // useEffect(() => {
  //   if (valueLocalStorage) {
  //     setShow(valueLocalStorage.role);
  //   }
  //   if(userData){
  //     window.history.go(-3)
  //   }
  //   if(userData){
  //     setShow(userData.role);
  //   }
  // }, [getLocalStorage, userData]);

  // function handelLog () {
  //   setShow(null)
  // }

  return (
    <div className='App'>
      <BrowserRouter>
        <>
          <div className='link'>
              <Routes>
                <Route path="/" element={<Statistics />}/>
                <Route path="/OrdersManagement" element={<OrdersManagement />}/>
                <Route path="/Customers" element={<Customers />}/>
                <Route path="/DataCustomers" element={<DataCustomers />}/>
                <Route path="/Categoires" element={<Categoires />}/>
                <Route path="/AddCategoire" element={<AddCategoire />}/>
                <Route path="/CategoiresDetial" element={<CategoiresDetial />}/>
                <Route path="/Transactions" element={<Transactions />}/>
                <Route path="/Brand" element={<Brand />}/>
                <Route path="/AddCompany" element={<AddCompany />}/>
                <Route path="/Adveritsement" element={<Adveritsement />}/>
                <Route path="/AddAdveritsement" element={<AddAdveritsement />}/>
                <Route path="/ProductsList" element={<ProductsList />}/>
                <Route path="/AddProduct" element={<AddProduct />}/>
                <Route path="/Favorites" element={<Favorites />}/>
              </Routes>
          </div>
        </> 
      <Sidebar/>
      </BrowserRouter>

    </div>
  );
}

export default App;
