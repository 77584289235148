
// Import the necessary modules from React, react-chartjs-2, and chart.js
import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// Register the required elements with Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

// Define the data for the pie chart
const data = {
  labels: [`Red`, 'Blue',],
  datasets: [
    {
      label: 'My Dataset',
      labe2: 'My Dataset',
      data: [200, 300],
      backgroundColor: [
        'rgba(255, 99, 132, 0.6)',
        'rgba(54, 162, 235, 0.6)',
        'rgba(255, 206, 86, 0.6)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

// Define the PieChart component
function PieChart() {
  return <Pie data={data} />;
}

// Export the component for use in other parts of your app
export default PieChart;