import './Statistics.css';
import SalesChart from './SalesChart';
import PieChart from './PieChart';
import RevenueComparisonChart from './RevenueComparisonChart'
import { faCartPlus, faChartPie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function Statistics() {
    const { t } = useTranslation();
    const [selectedDate, setSelectedDate] = useState('2023-01-01');
    const [comparisonDate, setComparisonDate] = useState('2022-01-01');
  
    const handleDateChange = (event) => setSelectedDate(event.target.value);
    const handleComparisonDateChange = (event) => setComparisonDate(event.target.value);
  
    return (
      <div className="Statistics">
          <div className='container'>
              <div className='row align-items-center'>
                  <div className='name_header d-flex'>
                      <i className='font-size3'><FontAwesomeIcon icon={faChartPie} /></i>
                      <p>{t('statistics.title')}</p>
                  </div>
              </div>
              <div className='container'>
                  <div className='row justify-content-between align-items-center mt-3'>
                      <div className='col-6'>
                          <SalesChart />
                      </div>

                      <div className='col-5'>
                          <div className='totals row justify-content-around align-items-center'>
                          <h4 className='mb-3'>{t('statistics.chooseDate')}</h4>
                              <div className='title col-5 g-0 border-0 m-0 p-0 mb-3'>
                                  <input className='w-100'
                                  type="date"
                                  value={selectedDate}
                                  onChange={handleDateChange}
                                  />
                              </div>

                              <div className='title col-5 g-0 border-0 m-0 p-0 mb-3'>
                                  {/* <label>{t('statistics.chooseComparisonDate')}</label> */}
                                  <input className='w-100'
                                  type="date"
                                  value={comparisonDate}
                                  onChange={handleComparisonDateChange}
                                  />
                              </div>
                              <div className='col-5'>
                                  <h5>{t('statistics.netProfits')}</h5>
                                  <p>{t('statistics.last7Days')}</p>
                                  <h4>0</h4>
                                  <p><span>12%</span> {t('statistics.last7Days')}</p>
                              </div>
                              <div className='col-5'>
                                  <h5>{t('statistics.averageOrderValue')}</h5>
                                  <p>{t('statistics.last7Days')}</p>
                                  <h4>0</h4>
                                  <p><span>12%</span> {t('statistics.last7Days')}</p>
                              </div>
                          </div>
                      
                      </div>
                  </div>
              </div>
              <div className='container'>
                  <div className='row justify-content-between align-items-center mt-5'>
                      <div className='col-6'>
                          <RevenueComparisonChart />
                      </div>

                      <div className='col-5'>
                          <div className='gross customer row justify-content-around'>
                              <div className='col-4 mb-3'>
                                  <div className='mb-3 px-2'>
                                      <h5>{t('statistics.grossProfits')}</h5>
                                      <span>12%</span>
                                  </div>
                              </div>
                              <div className='col-4'>
                                  <div className='px-2'>
                                      <h5>{t('statistics.profitMargins')}</h5>
                                      <span>12%</span>
                                  </div>
                              </div>
                              <div className='col-4'>
                                  <div className='px-2'>
                                      <h5>{t('statistics.durationOfStay')}</h5>
                                      <span>12%</span>
                                  </div>
                              </div>
                              <div className='col-4'>
                                  <div className='px-2'>
                                      <h5>{t('statistics.bounceRate')}</h5>
                                      <span>12%</span>
                                  </div>
                              </div>
                              <div className='col-4'>
                                  <div className='px-2'>
                                      <h5>{t('statistics.visitorsBought')}</h5>
                                      <span>12%</span>
                                  </div>
                              </div>
                              <div className='col-4'>
                                  <div className='px-2'>
                                      <h5>{t("statistics.visitorsDidntBuy")}</h5>
                                      <span>12%</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div className='container'>
                  <div className='row justify-content-between align-items-center mt-5'>
                      <div className='col-6'>
                          <h4>{t('statistics.inventoryManagement')}</h4>
                          <div className='m-auto col-6'>
                              <PieChart />
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    );
}

export default Statistics;