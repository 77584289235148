import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement } from 'chart.js';
import 'bootstrap/dist/css/bootstrap.min.css'; // تأكد من استيراد Bootstrap

Chart.register(CategoryScale, LinearScale, BarElement);

function SalesChart() {
  const [timePeriod, setTimePeriod] = useState('month');

  const labelsForPeriod = {
    week: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    month: ['January', 'February', 'March', 'April', 'May', 'June'],
    year: ['January', 'February', 'March', 'April', 'May', 'June'],
  };

  const dataForPeriod = {
    week: [50, 70, 80, 90, 60, 120, 110],
    month: [300, 400, 200, 500, 700, 600],
    year: [3200, 4100, 2300, 5400, 7500, 6200],
  };

  const data = {
    labels: labelsForPeriod[timePeriod],
    datasets: [
      {
        label: 'Sales',
        data: dataForPeriod[timePeriod],
        backgroundColor: 'rgba(75,192,192,0.6)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h3>Number of Visitors</h3>
        <div className="btn-group" role="group" aria-label="Time period selection">
          <button
            type="button"
            className={`btn btn-primary ${timePeriod === 'week' ? 'active' : ''}`}
            onClick={() => setTimePeriod('week')}
          >
            Week
          </button>
          <button
            type="button"
            className={`btn btn-primary ${timePeriod === 'month' ? 'active' : ''}`}
            onClick={() => setTimePeriod('month')}
          >
            Month
          </button>
          <button
            type="button"
            className={`btn btn-primary ${timePeriod === 'year' ? 'active' : ''}`}
            onClick={() => setTimePeriod('year')}
          >
            Year
          </button>
        </div>
      </div>
      <Bar data={data} options={options} />
    </div>
  );
}

export default SalesChart;