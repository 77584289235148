import React, { useState, useCallback } from "react";
import './AddCompany.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCartPlus, faChevronRight, faCloudUpload, faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from 'react-dropzone';
import { useNavigate } from "react-router-dom";

function AddCompany() {

    const navigate = useNavigate();
    const goBack = () => {
      navigate(-1)
    }



    // حالة للصندوق الأول (صورة واحدة فقط)
    const [singleImage, setSingleImage] = useState([]);
    const [errorSingleImage, setErrorSingleImage] = useState();

    // حالة للصندوق الثاني (حتى 5 صور)
    const [multipleImages, setMultipleImages] = useState([]);
    const [errorMultipleImages, setErrorMultipleImages] = useState();

    // دالة لتحميل الصورة الواحدة
    const onDropSingle = useCallback((acceptedFiles) => {
        const imageFiles = acceptedFiles.filter(file => file.type.startsWith('image/'));

        if (imageFiles.length > 0) {
            if (singleImage.length >= 1) {
                setErrorSingleImage('You can upload only one image');
            } else {
                setSingleImage(imageFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })));
                setErrorSingleImage('');
            }
        } else {
            setErrorSingleImage('Please upload only images');
        }
    }, [singleImage]);

    // دالة لتحميل الصور المتعددة
    const onDropMultiple = useCallback((acceptedFiles) => {
        const imageFiles = acceptedFiles.filter(file => file.type.startsWith('image/'));

        if (imageFiles.length > 0) {
            if (multipleImages.length + imageFiles.length <= 5) {
                setMultipleImages(prevImages => [
                    ...prevImages,
                    ...imageFiles.map(file => Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    }))
                ]);
                setErrorMultipleImages('');
            } else {
                setErrorMultipleImages('You can upload up to 5 images');
            }
        } else {
            setErrorMultipleImages('Please upload only images');
        }
    }, [multipleImages]);

    const { getRootProps: getRootPropsSingle, getInputProps: getInputPropsSingle } = useDropzone({ onDrop: onDropSingle, multiple: false });
    const { getRootProps: getRootPropsMultiple, getInputProps: getInputPropsMultiple } = useDropzone({ onDrop: onDropMultiple });


    // const [showSearch, setShowSearch] = useState(null)
    // const handleSearchChange = (e) => {
    //     const value = e.target.value;
    //     setShowSearch(value);
    // };

    // useEffect(() => {
    //     if (showSearch !== null) { 
    //       dispatch(getStudentSearch({value: showSearch}));
    //     }
    // }, [showSearch]); 
    
    // const StudentSearch = useSelector(state => state.Login.StudentSearch);
    // console.log(StudentSearch)







    const [type, setType] = useState(["Shop","Store","Mart",]);
    const [categories, setCategories] = useState(["Draft","Active","Archive",]);
    const [openingTime, setOpeningTime] = useState(['AM 00:12','AM 00:01','AM 00:02','AM 00:03','AM 00:04','AM 00:05','AM 00:06','AM 00:07','AM 00:08','AM 00:09','AM 00:10','AM 00:11','PM 00:12','PM 00:01','PM 00:02','PM 00:03','PM 00:04','PM 00:05','PM 00:06','PM 00:07','PM 00:08','PM 00:09','PM 00:10','PM 00:11',]);
    const [closingTime, setClosingTime] = useState(['AM 00:12','AM 00:01','AM 00:02','AM 00:03','AM 00:04','AM 00:05','AM 00:06','AM 00:07','AM 00:08','AM 00:09','AM 00:10','AM 00:11','PM 00:12','PM 00:01','PM 00:02','PM 00:03','PM 00:04','PM 00:05','PM 00:06','PM 00:07','PM 00:08','PM 00:09','PM 00:10','PM 00:11',]);
    const [openingDay, setOpeningDay] = useState(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']);
    const [closingDay, setClosingDay] = useState(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']);
  
      const[errorImage, setErrorImage] = useState();
      const[errorFile, setErrorFile] = useState();


    const text = categories[1]
    const[icon, setIcon] = useState(false)
    function handelClick() {
        setIcon(!icon)
    }
    const text1 = openingTime[1]
    const[icon1, setIcon1] = useState(false)
    function handelClick1() {
        setIcon1(!icon1)
    }
    const text2 = closingTime[1]
    const[icon2, setIcon2] = useState(false)
    function handelClick2() {
        setIcon2(!icon2)
    }
    const text3 = openingDay[1]
    const[icon3, setIcon3] = useState(false)
    function handelClick3() {
        setIcon3(!icon3)
    }
    const text4 = closingDay[1]
    const[icon4, setIcon4] = useState(false)
    function handelClick4() {
        setIcon4(!icon4)
    }
    const text5 = type[1]
    const[icon5, setIcon5] = useState(false)
    function handelClick5() {
        setIcon5(!icon5)
    }

  const [images, setImages] = useState([]);
  const[taskName, setTaskName] = useState()
  const[tasURL, setTaskURL] = useState()
  const[typeOpeningTime, setTypeOpeningTime] = useState()
  const[typeClosingTime, setTypeClosingTime] = useState()
  const[typeOpeningDay, setTypeOpeningDay] = useState()
  const[typeClosingDay, setTypeClosingDay] = useState()
  const[typeStore, setTypeStore] = useState()
  const[taskType, setTaskType] = useState()
  const[Descraption, setDescraption] = useState()

    const Data = {
        image: images,
        taskName: taskName,
        taskType: taskType,
        tasURL: tasURL,
        Descraption: Descraption,
    }

   const ResetInputs = () => {
        setTaskName(""); // مسح قيمة الاسم
        setDescraption(""); // مسح قيمة الوصف
        setTaskURL(""); // مسح قيمة رابط المشروع
        setTaskType(""); // مسح قيمة نوع المهمة
        setImages([]); // مسح قائمة الصور
    };
    const [errorImageUpload, setErrorImageUpload] = useState('');
    const[errorname, seterrorName] = useState()
    const[errordescription, seterrorDescription] = useState()
    const[errorcategory, seterrorCategory] = useState()
    const[errorimage, setErrorimage] = useState()
    const[errorurl, seterrorURL] = useState()
    function UploadTask(e) {
              e.preventDefault();
              let isError = false;
          
              if (!taskName) {
                  seterrorName("Error name");
                  isError = true;
              } else {
                  seterrorName("");
              }
          
              if (!Descraption) {
                  seterrorDescription("Error Description");
                  isError = true;
              } else {
                  seterrorDescription("");
              }
              if (!images.length) {
                  setErrorimage("Error color");
                  isError = true;
              } else {
                  setErrorimage("");
              }
      
              if (!singleImage.length && !multipleImages.length) {
                setErrorImageUpload("Please upload at least one image");
                isError = true;
            } else {
                setErrorImageUpload("");
            }
    
              if (!tasURL) {
                  seterrorURL("Error Date & Time");
                  isError = true;
              } else {
                  seterrorURL("");
              }
              if (!taskType) {
                  seterrorCategory("Error Category");
                  isError = true;
              } else {
                  seterrorCategory("");
              }
          
              if (isError) {
                  return;
              }
          
              ResetInputs()
          }
    return (
        <div className="AddCompany">
            <div className="container">
                <div className='row align-items-center'>
                  <div className='name_header d-flex'>
                    <i><FontAwesomeIcon icon={faCartPlus} /></i>
                    <p>Orders Management</p>
                  </div>
                  <div className="container icon">
                  <i className="i" onClick={goBack}><FontAwesomeIcon icon={faArrowLeft} /></i>
                  </div>
                </div>
                <form className="container row justify-content-between">
                    <div className="container_inpot col-8">
                        
                        {errorname && <p className="error" >{errorname}</p>}
                        <div className="div1">
                        <label for='name'>COMPANY NAME</label>
                        <input type="text" id="Name" onChange={(e) => setTaskName(e.target.value)} value={taskName}/>
                        </div>

                        {errordescription && <p className="error" >{errordescription}</p>}
                        <div className="div2">
                        <label for='tags'>LOCATION</label>
                        <input type="text" id="tags" onChange={(e) => setDescraption(e.target.value)} value={Descraption}/>
                        </div>

                        <div className="row justify-content-between">
                            {errorname && <p className="error" >{errorname}</p>}
                            <div className="box_AddCompany col-5" onClick={handelClick5}>
                                <label for='tags'>TYPE</label>
                                <div className="heade">
                                    <span>{typeStore ? typeStore : text5}</span>
                                    <i><FontAwesomeIcon icon={faChevronRight}/></i>
                                </div>
                                {icon5 &&
                                    <div className="cat">
                                        {type.map((e, i) => 
                                            <p onClick={() => (setTypeStore(e), setIcon5(!icon5))}>{e}</p>
                                        )}
                                    </div>
                                }
                            </div>

                            {errordescription && <p className="error" >{errordescription}</p>}
                            <div className="div2 col-5">
                            <label for='tags'>CATEGOIRE</label>
                            <input type="text" id="tags" onChange={(e) => setDescraption(e.target.value)} value={Descraption}/>
                            </div>
                        </div>

                        

                        <div className="row justify-content-between mt-4">
                            {errorname && <p className="error" >{errorname}</p>}
                            <div className="div1 col-5">
                                <label for='name'>DAYS FROM</label>
                                <input type="text" id="Name" onChange={(e) => setTaskName(e.target.value)} value={taskName}/>
                            </div>

                            {errordescription && <p className="error" >{errordescription}</p>}
                            <div className="div2 col-5">
                                <label for='tags'>DAYS TO</label>
                                <input type="text" id="tags" onChange={(e) => setDescraption(e.target.value)} value={Descraption}/>
                            </div>
                        </div>

                        <div className="row justify-content-between">
                            {errorname && <p className="error" >{errorname}</p>}
                            <div className="div1 col-3">
                            <label for='name'>Area</label>
                            <input type="text" id="Name" onChange={(e) => setTaskName(e.target.value)} value={taskName}/>
                            </div>

                            {errordescription && <p className="error" >{errordescription}</p>}
                            <div className="div2 col-3">
                            <label for='tags'>City</label>
                            <input type="text" id="tags" onChange={(e) => setDescraption(e.target.value)} value={Descraption}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">

                        {errorcategory && <p className="error" >{errorcategory}</p>}
                        <div className="box_AddCompany" onClick={handelClick}>
                            <label for='tags'>PRODUCT STATUS</label>
                            <div className="heade">
                                <span>{taskType ? taskType : text}</span>
                                <i className={`${icon ? 'activ_p' : 'noActiv_p'}`}><FontAwesomeIcon icon={faChevronRight}/></i>
                            </div>
                            {icon &&
                                <div className="cat">
                                    {categories.map((e, i) => 
                                        <p onClick={() => (setTaskType(e), setIcon(!icon))}>{e}</p>
                                    )}
                                </div>
                            }
                        </div>

                        
                        <div className="Add_img">
                            <p className="title2">COMPANY LOGO</p>
                            {errorImageUpload && <p className="error">{errorImageUpload}</p>}
                            <div className="add" {...getRootPropsSingle()}>
                                    <input {...getInputPropsSingle()} />
                                    {!singleImage.length && (
                                    <div className="upload-placeholder">
                                        <i><FontAwesomeIcon icon={faCloudUpload} /></i>
                                        <p>Drag and drop a file to upload</p>
                                    </div>
                                )}
                                <div className="image-preview single">
                                    {singleImage.map((file, index) => (
                                        <img key={index} src={file.preview} alt={`upload preview ${index}` } className="img"/>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <p>PAYMENT METHODS</p>

                        <div className="col-5">
                            <div className="check col-5">
                                <input type="checkbox" id="tags3" name="check" onChange={(e) => setDescraption(e.target.value)} value={Descraption}/>
                                <label for='tags3'>Credit Card</label>
                            </div>
                           
                            <div className="check col-5">
                                <input type="checkbox" id="tags2" name="check" onChange={(e) => setDescraption(e.target.value)} value={Descraption}/>
                                <label for='tags2'>Cash Payment</label>
                            </div>
                            <div className="check col-5">
                                <input type="checkbox" id="tags1" name="check" onChange={(e) => setDescraption(e.target.value)} value={Descraption}/>
                                <label for='tags1'>Installments</label>
                            </div>
                        </div>

                    </div>
                        <button className="col-5 mt-3" onClick={UploadTask} type="sbmit" >Create</button>
                </form>
            </div>
        </div>
    );
}

export default AddCompany;

























// import React, { useState, useCallback } from "react";
// import './AddCompany.css';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft, faCartPlus, faChevronRight, faCloudUpload, faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
// import { useDropzone } from 'react-dropzone';
// import { useNavigate } from "react-router-dom";

// function AddCompany() {

//     const navigate = useNavigate();
//     const goBack = () => {
//       navigate(-1)
//     }



//     // حالة للصندوق الأول (صورة واحدة فقط)
//     const [singleImage, setSingleImage] = useState([]);
//     const [errorSingleImage, setErrorSingleImage] = useState();

//     // حالة للصندوق الثاني (حتى 5 صور)
//     const [multipleImages, setMultipleImages] = useState([]);
//     const [errorMultipleImages, setErrorMultipleImages] = useState();

//     // دالة لتحميل الصورة الواحدة
//     const onDropSingle = useCallback((acceptedFiles) => {
//         const imageFiles = acceptedFiles.filter(file => file.type.startsWith('image/'));

//         if (imageFiles.length > 0) {
//             if (singleImage.length >= 1) {
//                 setErrorSingleImage('You can upload only one image');
//             } else {
//                 setSingleImage(imageFiles.map(file => Object.assign(file, {
//                     preview: URL.createObjectURL(file)
//                 })));
//                 setErrorSingleImage('');
//             }
//         } else {
//             setErrorSingleImage('Please upload only images');
//         }
//     }, [singleImage]);

//     // دالة لتحميل الصور المتعددة
//     const onDropMultiple = useCallback((acceptedFiles) => {
//         const imageFiles = acceptedFiles.filter(file => file.type.startsWith('image/'));

//         if (imageFiles.length > 0) {
//             if (multipleImages.length + imageFiles.length <= 5) {
//                 setMultipleImages(prevImages => [
//                     ...prevImages,
//                     ...imageFiles.map(file => Object.assign(file, {
//                         preview: URL.createObjectURL(file)
//                     }))
//                 ]);
//                 setErrorMultipleImages('');
//             } else {
//                 setErrorMultipleImages('You can upload up to 5 images');
//             }
//         } else {
//             setErrorMultipleImages('Please upload only images');
//         }
//     }, [multipleImages]);

//     const { getRootProps: getRootPropsSingle, getInputProps: getInputPropsSingle } = useDropzone({ onDrop: onDropSingle, multiple: false });
//     const { getRootProps: getRootPropsMultiple, getInputProps: getInputPropsMultiple } = useDropzone({ onDrop: onDropMultiple });


//     // const [showSearch, setShowSearch] = useState(null)
//     // const handleSearchChange = (e) => {
//     //     const value = e.target.value;
//     //     setShowSearch(value);
//     // };

//     // useEffect(() => {
//     //     if (showSearch !== null) { 
//     //       dispatch(getStudentSearch({value: showSearch}));
//     //     }
//     // }, [showSearch]); 
    
//     // const StudentSearch = useSelector(state => state.Login.StudentSearch);
//     // console.log(StudentSearch)







//     const [categories, setCategories] = useState([
//         "Draft",
//         "Active",
//         "Archive",
//       ]);

//       const[errorImage, setErrorImage] = useState();
//       const[errorFile, setErrorFile] = useState();


//     const text = 'choose task type'
//     const[icon, setIcon] = useState(false)
//     function handelClick() {
//         setIcon(!icon)
//     }

//   const [images, setImages] = useState([]);
//   const[taskName, setTaskName] = useState()
//   const[tasURL, setTaskURL] = useState()
//   const[taskType, setTaskType] = useState()
//   const[Descraption, setDescraption] = useState()
//     const Data = {
//         image: images,
//         taskName: taskName,
//         taskType: taskType,
//         tasURL: tasURL,
//         Descraption: Descraption,
//     }
//     console.log(Data)

//    const ResetInputs = () => {
//         setTaskName(""); // مسح قيمة الاسم
//         setDescraption(""); // مسح قيمة الوصف
//         setTaskURL(""); // مسح قيمة رابط المشروع
//         setTaskType(""); // مسح قيمة نوع المهمة
//         setImages([]); // مسح قائمة الصور
//     };
//     const [errorImageUpload, setErrorImageUpload] = useState('');
//     const[errorname, seterrorName] = useState()
//     const[errordescription, seterrorDescription] = useState()
//     const[errorcategory, seterrorCategory] = useState()
//     const[errorimage, setErrorimage] = useState()
//     const[errorurl, seterrorURL] = useState()
//     function UploadTask(e) {
//               e.preventDefault();
//               let isError = false;
          
//               if (!taskName) {
//                   seterrorName("Error name");
//                   isError = true;
//               } else {
//                   seterrorName("");
//               }
          
//               if (!Descraption) {
//                   seterrorDescription("Error Description");
//                   isError = true;
//               } else {
//                   seterrorDescription("");
//               }
//               if (!images.length) {
//                   setErrorimage("Error color");
//                   isError = true;
//               } else {
//                   setErrorimage("");
//               }
      
//               if (!singleImage.length && !multipleImages.length) {
//                 setErrorImageUpload("Please upload at least one image");
//                 isError = true;
//             } else {
//                 setErrorImageUpload("");
//             }
    
//               if (!tasURL) {
//                   seterrorURL("Error Date & Time");
//                   isError = true;
//               } else {
//                   seterrorURL("");
//               }
//               if (!taskType) {
//                   seterrorCategory("Error Category");
//                   isError = true;
//               } else {
//                   seterrorCategory("");
//               }
          
//               if (isError) {
//                   return;
//               }
          
//               ResetInputs()
//           }
//     return (
//         <div className="AddCompany">
//             <div className="container">
//                 <div className='row align-items-center'>
//                   <div className='name_header d-flex'>
//                     <i><FontAwesomeIcon icon={faCartPlus} /></i>
//                     <p>Orders Management</p>
//                   </div>
//                   <div className="container icon">
//                   <i className="i" onClick={goBack}><FontAwesomeIcon icon={faArrowLeft} /></i>
//                   </div>
//                 </div>
//                 <form className="container row justify-content-around">
//                   <div className="col-6">
//                     {/* الصندوق الأول - صورة واحدة فقط */}
//                     <div className="Add_img">
//                         <p>Add a single photo</p>
//                         {errorImageUpload && <p className="error">{errorImageUpload}</p>}
//                         <div className="add" {...getRootPropsSingle()}>
//                             <input {...getInputPropsSingle()} />
//                             {!singleImage.length && (
//                                 <div className="upload-placeholder">
//                                     <i><FontAwesomeIcon icon={faCloudUpload} /></i>
//                                     <p>Drag and drop a file to upload</p>
//                                 </div>
//                             )}
//                             <div className="image-preview single">
//                                 {singleImage.map((file, index) => (
//                                     <img key={index} src={file.preview} alt={`upload preview ${index}` } className="img"/>
//                                 ))}
//                             </div>
//                         </div>
//                     </div>

//                     {/* الصندوق الثاني - حتى 5 صور */}
//                     <div className="Add_img">
//                         <p>Add multiple photos (up to 5)</p>
//                         {errorImageUpload && <p className="error">{errorImageUpload}</p>}

//                         <div className="add" {...getRootPropsMultiple()}>
//                             <input {...getInputPropsMultiple()} />
//                             {!multipleImages.length && (
//                                 <div className="upload-placeholder">
//                                     <i><FontAwesomeIcon icon={faCloudUpload} /></i>
//                                     <p>Drag and drop files to upload</p>
//                                 </div>
//                             )}
//                             <div className={`image-preview ${multipleImages.length === 1 ? 'single' : 'multiple'} row`}>
//                                 {multipleImages.map((file, index) => (
//                                     <img key={index} src={file.preview} alt={`upload preview ${index}`} className="imgs col-4"/>
//                                 ))}
//                             </div>
//                         </div>
//                     </div>
//                   </div>


//                   <div className="project_data col-5">
//                     {errorname && <p className="error" >{errorname}</p>}
//                     <div className="div1">
//                       <label for='name'>TITLE</label>
//                       <input type="text" id="Name" onChange={(e) => setTaskName(e.target.value)} value={taskName}/>
//                     </div>
//                     {errorcategory && <p className="error" >{errorcategory}</p>}
//                     <div className="box" onClick={handelClick}>
//                         <label for='tags'>CATEGOIRE STATUS</label>
//                         <div className="heade">
//                             <span>{taskType ? taskType : text}</span>
//                             <i className={`${icon ? 'activ_p' : 'noActiv_p'}`}><FontAwesomeIcon icon={faChevronRight}/></i>
//                         </div>
//                         {icon &&
//                             <div className="cat">
//                                 {categories.map((e, i) => 
//                                     <p onClick={() => (setTaskType(e), setIcon(!icon))}>{e}</p>
//                                 )}
//                             </div>
//                         }
//                     </div>
//                     {errordescription && <p className="error" >{errordescription}</p>}
//                     <div className="div2">
//                       <label for='tags'>TAGS</label>
//                       <input type="text" id="tags" onChange={(e) => setDescraption(e.target.value)} value={Descraption}/>
//                     </div>

//                     <button onClick={UploadTask} type="sbmit">Create</button>
//                   </div>
    
//                 </form>
//             </div>
//         </div>
//     );
// }

// export default AddCompany;