import { 
    faHouse, 
    faBookmark, 
    faMessage, 
    faUser, 
    faCalendarCheck, 
    faArrowRightFromBracket,
    faShoppingCart, 
    faTags, 
    faBullhorn, 
    faBoxOpen, 
    faChartPie, 
    faTrademark,
    faPlusCircle,
    faInfoCircle,
    faEnvelope,
    faClipboardList,
    faAd,
    faBell,
    faCartPlus,
    faUsers,
    faHeart
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './Sidebar.css';
import { faListAlt } from '@fortawesome/free-solid-svg-icons/faListAlt';
import logo from './images/Group.png';
import img1 from './images/Asset 19.png';

function Sidebar(props) {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');
    const [show, setShow] = useState(null);
    const [icon, setIcon] = useState(false);

    useEffect(() => {
        i18n.changeLanguage(language);
        document.documentElement.dir = language === 'ar' ? 'rtl' : 'ltr';
        document.body.style.textAlign = language === 'ar' ? 'right' : 'left';
    }, [language, i18n]);

    const handleLanguageChange = (lang) => {
        setLanguage(lang);
        localStorage.setItem('language', lang);
        setIcon(false); // إغلاق قائمة اللغات بعد التغيير
    };

    const handleClick = () => {
        setIcon(!icon);
    };

    return (
        <div className='Sidebar'>
            <div className='logo'>
                <img src={logo} alt='logo' />
            </div>
            <div className='logo'>
                <div>
                    <img src={img1} alt='User'/>
                    <span>Mohamed</span>
                </div>
                <i><FontAwesomeIcon icon={faBell} /></i>
            </div>
            {show === null &&
                <ul>
                    <li>
                        <NavLink className="link" to="/"><i><FontAwesomeIcon icon={faChartPie} /></i> {t('sidebar.statistics')}</NavLink>
                    </li>
                    <li>
                        <NavLink className="link" to="/OrdersManagement"><i><FontAwesomeIcon icon={faCartPlus} /></i> {t('sidebar.orders_management')}</NavLink>
                    </li>
                    <li>
                        <NavLink className="link" to="/Customers"><i><FontAwesomeIcon icon={faUsers} /></i> {t('sidebar.customers')}</NavLink>
                    </li>
                    <li>
                        <NavLink className="link" to="/Categoires"><i><FontAwesomeIcon icon={faTags} /></i> {t('sidebar.categories')}</NavLink>
                    </li>
                    <li>
                        <NavLink className="link" to="/Transactions"><i><FontAwesomeIcon icon={faCalendarCheck} /></i> {t('sidebar.transactions')}</NavLink>
                    </li>
                    <li>
                        <NavLink className="link" to="/Brand"><i><FontAwesomeIcon icon={faTrademark} /></i> {t('sidebar.brand')}</NavLink>
                    </li>
                    <li>
                        <NavLink className="link" to="/Adveritsement"><i><FontAwesomeIcon icon={faAd} /></i> {t('sidebar.advertisement')}</NavLink>
                    </li>
                    <li>
                        <NavLink className="link" to="/AddProduct"><i><FontAwesomeIcon icon={faPlusCircle} /></i> {t('sidebar.add_product')}</NavLink>
                    </li>
                    <li>
                        <NavLink className="link" to="/ProductsList"><i><FontAwesomeIcon icon={faListAlt} /></i> {t('sidebar.products_list')}</NavLink>
                    </li>
                    <li>
                        <NavLink className="link" to="/Favorites"><i><FontAwesomeIcon icon={faHeart} /></i> {t('Favorites')}</NavLink>
                    </li>
                    <div className='langiches'>
                        <p onClick={handleClick}>{t('sidebar.languages')}</p>
                        {icon && (
                            <div>
                                <span onClick={() => handleLanguageChange('ar')}>{t('sidebar.arabic')}</span>
                                <span onClick={() => handleLanguageChange('en')}>{t('sidebar.english')}</span>
                            </div>
                        )}
                    </div>
                    <NavLink to={'/'}>
                        <button>{t('sidebar.logout')}<i><FontAwesomeIcon icon={faArrowRightFromBracket} /></i> </button>
                    </NavLink>
                </ul>
            }
            {show === 'Visitor' &&
                <ul>
                    <li>
                        <NavLink className="link" to="/"><i><FontAwesomeIcon icon={faHouse} /></i> {t('sidebar.home')}</NavLink>
                    </li>
                    <li>
                        <NavLink className="link" to="/Chat"><i><FontAwesomeIcon icon={faMessage} /></i> {t('sidebar.chat')}</NavLink>
                    </li>
                    <li>
                        <NavLink className="link" to="/Favorites"><i><FontAwesomeIcon icon={faBookmark} /></i> {t('sidebar.favorites')}</NavLink>
                    </li>
                    <li>
                        <NavLink className="link" to="/About"><i><FontAwesomeIcon icon={faInfoCircle} /></i> {t('sidebar.about')}</NavLink>
                    </li>
                    <li>
                        <NavLink className="link" to="/ContactUs"><i><FontAwesomeIcon icon={faEnvelope} /></i> {t('sidebar.contact_us')}</NavLink>
                    </li>
                    <div className='langiches'>
                        <p onClick={handleClick}>{t('sidebar.languages')}</p>
                        {icon && (
                            <div>
                                <span onClick={() => handleLanguageChange('ar')}>{t('sidebar.arabic')}</span>
                                <span onClick={() => handleLanguageChange('en')}>{t('sidebar.english')}</span>
                            </div>
                        )}
                    </div>
                    <NavLink to={'/'}>
                        <button>{t('sidebar.logout')}<i><FontAwesomeIcon icon={faArrowRightFromBracket} /></i> </button>
                    </NavLink>
                </ul>
            }
        </div>
    );
}

export default Sidebar;