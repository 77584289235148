import './ProductsList.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faChevronRight, faEdit, faListAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import img1 from './images/Asset 5.png';
import img2 from './images/IMG-20240723-WA0008.jpg';
import img3 from './images/bWseWtl0lxGx1c2Qn8u5kySbfXzViOKx970C0gci.jpg';
import img4 from './images/Asset 1.png';
import { useTranslation } from 'react-i18next';

function ProductsList() {
  const { t } = useTranslation();

  const Orders = [
    { id: 1, NAME: 'shirt', img: img1, CATEGOIRE: 'snacks', COMPANY: 'Hmam', INVENTORY: 1, STATUS: '55' },
    { id: 2, NAME: 'shirt', img: img2, CATEGOIRE: 'snacks', COMPANY: 'Elmostafa', INVENTORY: 1, STATUS: '55' },
    { id: 3, NAME: 'shirt', img: img3, CATEGOIRE: 'snacks', COMPANY: 'Ngar', INVENTORY: 1, STATUS: '55' },
    { id: 4, NAME: 'shirt', img: img4, CATEGOIRE: 'snacks', COMPANY: 'hmam', INVENTORY: 1, STATUS: '55' },
  ];

  const listOrders = [t('products_list.product_name'), t('products_list.category'), t('products_list.company'), t('products_list.inventory'), t('products_list.status'), t('products_list.action')];
  const listHidear = [t('products_list.active'), t('products_list.draft'), t('products_list.archive')];

  const [categories, setCategories] = useState([t('products_list.draft'), t('products_list.active'), t('products_list.archive')]);
  const [openCategory, setOpenCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState({});

  const handleCategoryClick = (id) => {
    setOpenCategory(openCategory === id ? null : id);
  };

  const handleSelectCategory = (id, category) => {
    setSelectedCategory({ ...selectedCategory, [id]: category });
    setOpenCategory(null);
  };

  return (
    <div className="ProductsList">
      <div className='container-fluid'>
        <div className='row align-items-center'>
          <div className='name_header d-flex'>
            <i className='font-size3'><FontAwesomeIcon icon={faListAlt} /></i>
            <p>{t('products_list.products_list')}</p>
          </div>
        </div>

        <div className='container'>
          <div className='row'>
            <div className='Pending'>
              {listHidear.map((e) =>
                <p key={e}>{e}</p>
              )}
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='row'>
            <div className='sarsh'>
              <input placeholder={t('products_list.search')} />
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='Orders'>
            <div className='Orders_border row'>
              {listOrders.map((e, index) => (
                <p className='col-2 text-center mb-3' key={index}>{e}</p>
              ))}
            </div>

            {Orders.map((order) => (
              <div key={order.id}>
                <div className='Orders_border row align-items-center'>
                  <p className='col-2 text-center'>
                    <img src={order.img} alt={order.NAME}/>
                    <span>{order.NAME}</span>
                  </p>
                  <p className='col-2 text-center'>{order.CATEGOIRE}</p>
                  <p className='col-2 text-center'>{order.COMPANY}</p>
                  <p className='col-2 text-center'>{order.INVENTORY}</p>
                  <p className='col-2 text-center'>
                    <div className="box_add" onClick={() => handleCategoryClick(order.id)}>
                      <div className="heade">
                        <span>{selectedCategory[order.id] || categories[1]}</span>
                        <i><FontAwesomeIcon icon={faChevronRight} /></i>
                      </div>
                      {openCategory === order.id &&
                        <div className="cat2">
                          {categories.map((e, i) =>
                            <p key={i} onClick={() => handleSelectCategory(order.id, e)}>{e}</p>
                          )}
                        </div>
                      }
                    </div>
                  </p>
                  <p className='col-2 text-center'>
                    <span className='span_icon'><FontAwesomeIcon icon={faEdit} /></span>
                    <span className='span_icon'><FontAwesomeIcon icon={faTrash} /></span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductsList;