import './Transactions.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function Transactions() {
  const { t } = useTranslation();

  const Orders = [
    { id: 1, CUSTOMER: 'mohammed Bikdash', DATE: '2024-08-11', PAYMENT: 5, TOTAL: '89 JOD ', METHOD: 'cc', STATUS: 'Cancelled' },
    { id: 2, CUSTOMER: 'mohammed Bikdash', DATE: '2024-08-11', PAYMENT: 5, TOTAL: '89 JOD ', METHOD: 'cc', STATUS: 'Cancelled' },
    { id: 3, CUSTOMER: 'mohammed Bikdash', DATE: '2024-08-11', PAYMENT: 5, TOTAL: '89 JOD ', METHOD: 'cc', STATUS: 'Cancelled' },
  ];

  const listOrders = t('transactions.columns', { returnObjects: true });
  const categories = t('transactions.categories', { returnObjects: true });

  return (
    <div className="Transactions">
      <div className='container-fluid'>
        <div className='row align-items-center'>
          <div className='name_header d-flex'>
            <i className='font-size3'><FontAwesomeIcon icon={faCalendarCheck} /></i>
            <p>{t('transactions.title')}</p>
          </div>
        </div>

        <div className='container'>
          <div className='row'>
            <div className='Pending'>
              {categories.map((e, i) => (
                <p key={i}>{e}</p>
              ))}
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='row'>
            <div className='sarsh'>
              <input placeholder={t('transactions.search_placeholder')} />
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='Orders'>
            <div className='Orders_border row justify-content-around'>
              {listOrders.map((e, index) => (
                <p className='col-1 text-center' key={index}>{e}</p>
              ))}
            </div>

            {Orders.map((order) => (
              <div key={order.id}>
                <div className='div_Transactions'>
                  <p>{order.id}</p>
                  <p>{order.CUSTOMER}</p>
                  <p>{order.DATE}</p>
                  <p>{order.PAYMENT}</p>
                  <p>{order.TOTAL}</p>
                  <p>{order.METHOD}</p>
                  <p>{order.STATUS}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Transactions;