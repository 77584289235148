import './Brand.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faEdit, faPlus, faTrademark, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import img1 from './images/88209500.jpg';
import img2 from './images/Asset 19.png';
import img3 from './images/Asset 22.png';

function Brand() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const gotoCategoiresDetial = () => {
    navigate('/CategoiresDetial');
  };

  const AddCompany = () => {
    navigate('/AddCompany');
  };

  const Orders = [
    { id: 1, IMAGE: img1, NAME: 'Shirt', STATUS: 5, ACTION: '89 JOD' },
    { id: 2, IMAGE: img2, NAME: 'Glasses', STATUS: 5, ACTION: '89 JOD' },
    { id: 3, IMAGE: img3, NAME: 'Bag', STATUS: 5, ACTION: '89 JOD' },
  ];

  const listOrders = t('brand.columns', { returnObjects: true });
  const categories = t('brand.categories', { returnObjects: true });

  const [openCategory, setOpenCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState({});

  const handleCategoryClick = (id) => {
    setOpenCategory(openCategory === id ? null : id);
  };

  const handleSelectCategory = (id, category) => {
    setSelectedCategory({ ...selectedCategory, [id]: category });
    setOpenCategory(null);
  };

  return (
    <div className="Brand">
      <div className='container-fluid'>
        <div className='row align-items-center'>
          <div className='name_header d-flex'>
            <i className='font-size3'><FontAwesomeIcon icon={faTrademark} /></i>
            <p>{t('brand.title')}</p>
          </div>
        </div>

        <div className='container'>
          <div className='row'>
            <div className='sarsh by Name'>
              <input placeholder={t('brand.search_placeholder')} />
              <div className='AddCat' onClick={AddCompany}>
                <span><FontAwesomeIcon icon={faPlus} /></span>
                <span>{t('brand.add_category')}</span>
              </div>
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='Orders'>
            <div className='Recent'>{t('brand.recent_orders')}</div>
            <div className='Orders_border row justify-content-around'>
              {listOrders.map((e, index) => (
                <p className='col-2 text-center' key={index}>{e}</p>
              ))}
            </div>

            {Orders.map((order) => (
              <div key={order.id}>
                <div className='Orders_border row justify-content-around'>
                  <p className='col-2 text-center' onClick={gotoCategoiresDetial}>{order.id}</p>
                  <p className='col-2 text-center' onClick={gotoCategoiresDetial}><img src={order.IMAGE} alt={order.NAME} /></p>
                  <p className='col-2 text-center' onClick={gotoCategoiresDetial}>{order.NAME}</p>
                  <p className='col-2 text-center'>
                    <div className="box_add" onClick={() => handleCategoryClick(order.id)}>
                      <div className="heade">
                        <span>{selectedCategory[order.id] || categories[1]}</span>
                        <i><FontAwesomeIcon icon={faChevronRight} /></i>
                      </div>
                      {openCategory === order.id &&
                        <div className="cat2">
                          {categories.map((e, i) =>
                            <p key={i} onClick={() => handleSelectCategory(order.id, e)}>{e}</p>
                          )}
                        </div>
                      }
                    </div>
                  </p>
                  <p className='col-2 text-center'>
                    <span className='span_icon'><FontAwesomeIcon icon={faEdit} /></span>
                    <span className='span_icon'><FontAwesomeIcon icon={faTrash} /></span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Brand;
